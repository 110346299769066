/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum AuthActionTypes {
  SET_AUTH_ERROR = 'AUTH/SET_AUTH_ERROR',
  SET_NEEDS_FULL_AUTH_REDIRECT = 'AUTH/SET_NEEDS_FULL_AUTH_REDIRECT',
  SET_NEEDS_SILENT_REFRESH = 'AUTH/SET_NEEDS_SILENT_REFRESH',
  SET_TOKENS = 'AUTH/SET_TOKENS',
}

export type AuthState = {
  error?: Error
  needsFullAuthRedirect: boolean
  needsSilentRefresh: boolean
  refreshToken: string | undefined
  token: string | undefined
}
