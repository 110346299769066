import * as React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'

import { UnitPreferencesReduxProps } from '../preferences/preferences.types'
import UnitPreferencesPage from '../preferences/UnitPreferencesPage/UnitPreferencesPage'

export type HousekeepersIndexProps = UnitPreferencesReduxProps

const HousekeepersIndex: React.FC<HousekeepersIndexProps> = props => {
  return (
    <Switch>
      <Route
        path="/zone/:zoneId/housekeepers/preferences"
        render={() => <UnitPreferencesPage {...props} />}
      />

      <Redirect
        exact={true}
        from="/zone/:zoneId/housekeepers"
        to="/zone/:zoneId/housekeepers/preferences"
      />
    </Switch>
  )
}

export default HousekeepersIndex
