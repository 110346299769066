import { createSelector } from 'reselect'

import { ApplicationState } from 'app/hkhub/store/store'

import { UnitDrawerState } from '../ui.types'

export const getUnitDrawerState = createSelector(
  (state: ApplicationState) => state.ui.drawers.unitDrawer,
  (unitDrawerState: UnitDrawerState) => unitDrawerState,
)
