import { map, prop, uniq } from 'lodash/fp'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePrevious } from 'react-use'

import { SentryErrorBoundary } from 'packages/common'
import {
  addDays,
  createDateString,
  eachDay,
  isWithinInterval,
  startOfDay,
  startOfWeek,
} from 'packages/utils/dateHelpers'

import { useZoneContext } from 'app/hkhub/components/zone/ZonePage/ZonePage.context'
import { fetchCleansByZoneAndDate } from 'app/hkhub/store/cleans/actions'
import { fetchCoverageByZoneAndDateRange } from 'app/hkhub/store/coverage/actions'
import { fetchHkAvailabilities } from 'app/hkhub/store/hkAvailabilities/actions'
import { ApplicationState } from 'app/hkhub/store/store'
import { fetchIncompleteTicketsByZone } from 'app/hkhub/store/tickets/actions'
import { fetchTicketsByZoneAndDate } from 'app/hkhub/store/tickets/actions/fetchTicketsByZoneAndDate'
import { fetchVisitsByZoneAndDate } from 'app/hkhub/store/visits/actions/fetchVisitsByZoneAndDate'

import { ScheduleControls } from '../components/common/ScheduleControls'
import { TicketDrawerContainer } from '../components/common/TicketDrawer'
import { UnitDrawerContainer } from '../components/common/UnitDrawer/UnitDrawer.container'
import { VirtualizedSchedule } from '../components/common/VirtualizedSchedule'
import { UnitFiltersContextWrapper } from '../components/units/contexts'
import { useScheduleMatchParams, useScheduleNavigation } from '../hooks'
import { refetchDataForNextAndPrev } from './SchedulePage.helpers'

const REFETCH_DEBOUNCE_TIME = 500

const getDateRangeStart = (date: Date, dayCount: number): Date =>
  dayCount === 7 ? startOfWeek(date) : date

const getDateRangeEnd = (date: Date, dayCount: number): Date =>
  dayCount === 1 ? date : addDays(date, dayCount - 1)

export const SchedulePage: React.FC = () => {
  const dispatch = useDispatch()

  const { zone } = useZoneContext()
  const { date, dayCount } = useScheduleMatchParams()
  const prevDate = usePrevious(date)
  const { navigate } = useScheduleNavigation()

  const refetchTimeout = React.useRef<number | null>(null)
  const [isFetching, setIsFetching] = React.useState(true)

  /**
   * Determines if we have any cleans for the current date range in Redux.
   * Will return true as soon as the first clean is found.
   */
  const hasCleansForWeek = useSelector((state: ApplicationState) => {
    const start = startOfWeek(date)
    const end = addDays(start, 6)

    const dateStrings: string[] = uniq(
      map(prop('attributes.effectiveDate'), state.cleans.data),
    )
    const cleanDates: Date[] = map(startOfDay, dateStrings)

    return !!cleanDates.find(cleanDate =>
      isWithinInterval(cleanDate, { end, start }),
    )
  })

  const startDate = React.useMemo(
    () => getDateRangeStart(date, dayCount),
    [date, dayCount],
  )

  const endDate = React.useMemo(
    () => getDateRangeEnd(startDate, dayCount),
    [dayCount, startDate],
  )

  // start and end dates for our current view; note that for day view, this is simply the same date twice
  const dateRange = React.useMemo(
    () => eachDay(startDate, endDate),
    [startDate, endDate],
  )

  const handleChangeDate = React.useCallback(
    (nextDate: Date) => {
      navigate({ date: nextDate })
    },
    [navigate],
  )

  /**
   * Fetches all data needed for the current date/view combination.
   * Note that the requests may differ slightly between the two views.
   */
  const refetchData = React.useCallback(async () => {
    // build request list common to both views
    const promises = [
      dispatch(
        fetchCoverageByZoneAndDateRange({
          dateRange: [createDateString(startDate), createDateString(endDate)],
          zoneId: zone.id,
        }),
      ),
      dispatch(fetchCleansByZoneAndDate(zone.id, startDate, endDate)),
      dispatch(fetchTicketsByZoneAndDate(zone.id, startDate, endDate)),
      dispatch(fetchHkAvailabilities(zone.id, startDate, endDate)),
    ]

    await Promise.all(promises)
    await dispatch(fetchVisitsByZoneAndDate(zone.id, startDate, endDate))
    await dispatch(fetchIncompleteTicketsByZone(zone.id))
    setIsFetching(false)

    refetchDataForNextAndPrev({
      dayCount,
      dispatch,
      startDate,
      zoneId: zone.id,
    })
  }, [dayCount, dispatch, endDate, startDate, zone.id])

  const debouncedRefetch = React.useCallback(() => {
    if (refetchTimeout.current) {
      clearTimeout(refetchTimeout.current)
    }

    refetchTimeout.current = window.setTimeout(
      refetchData,
      REFETCH_DEBOUNCE_TIME,
    )
  }, [refetchData])

  React.useEffect(() => {
    if (date !== prevDate) {
      setIsFetching(true)
      debouncedRefetch()
    }
  }, [date, debouncedRefetch, prevDate])

  return (
    <SentryErrorBoundary boundary={'schedule-page'}>
      <UnitFiltersContextWrapper>
        <ScheduleControls onChangeDate={handleChangeDate} />
        <UnitDrawerContainer />
        <TicketDrawerContainer />

        <VirtualizedSchedule
          dateRange={dateRange}
          isLoading={!hasCleansForWeek && isFetching}
        />
      </UnitFiltersContextWrapper>
    </SentryErrorBoundary>
  )
}
