import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { getLockboxByUnitId } from 'app/hkhub/store/lockboxes/selectors'
import { ApplicationState } from 'app/hkhub/store/store'
import { getUnitById } from 'app/hkhub/store/units/selectors'
import { Visit } from 'app/hkhub/store/visits'
import { fetchVisitById } from 'app/hkhub/store/visits/actions'
import { getVisitById } from 'app/hkhub/store/visits/selectors/getVisitById'

import { VisitDrawer } from './VisitDrawer'

type VisitDrawerContainerProps = {
  isOpen: boolean
  onAfterExit: () => void
  visitId: string | undefined
}

export const VisitDrawerContainer: React.FC<VisitDrawerContainerProps> = ({
  isOpen,
  onAfterExit,
  visitId,
}) => {
  const dispatch = useDispatch()

  const visit: Visit | undefined = useSelector((state: ApplicationState) =>
    visitId ? getVisitById(state, visitId) : undefined,
  )

  const unit = useSelector((state: ApplicationState) =>
    getUnitById(state.units, visit?.unitId || ''),
  )

  const lockbox = useSelector((state: ApplicationState) =>
    getLockboxByUnitId(state, unit?.id || ''),
  )

  const [requestState, fetchVisit] = useAsyncFnWithReset(
    async (visitId: string) => dispatch(fetchVisitById(visitId)),
    [dispatch],
  )

  React.useEffect(() => {
    if (isOpen && visitId) {
      fetchVisit(visitId)
    }
  }, [visitId, isOpen, fetchVisit])

  const [state, setState] = React.useState({
    forceCloseDrawer: false,
  })

  const handleAfterExit = React.useCallback(() => {
    setState(prev => ({
      ...prev,
      forceCloseDrawer: false,
    }))

    onAfterExit()
  }, [onAfterExit])

  const handleForceCloseDrawer = React.useCallback(() => {
    setState(prev => ({
      ...prev,
      forceCloseDrawer: true,
    }))
  }, [])

  return !!unit ? (
    <VisitDrawer
      fetchingVisit={requestState.loading}
      forceCloseDrawer={state.forceCloseDrawer}
      handleAfterExit={handleAfterExit}
      handleForceCloseDrawer={handleForceCloseDrawer}
      isOpen={isOpen}
      lockbox={lockbox}
      unit={unit}
      visit={visit}
    />
  ) : null
}
