import { ClassNames } from '@emotion/react'
import { startCase } from 'lodash/fp'
import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink as RouterNavLink } from 'react-router-dom'

import { logoutWithOneLogin } from 'packages/auth'
import {
  Button,
  NotificationBadge,
  UseManageDrawerState,
} from 'packages/common'

import { SelectedZoneDisplay } from 'app/hkhub/components/core/components/SelectedZoneDisplay'
import { Slugs, useI18n } from 'app/hkhub/i18n'
import { getAllNotificationsCount } from 'app/hkhub/store/notifications/selectors'
import { Zone } from 'app/hkhub/store/zones/zones.types'
import { useHubRouting } from 'app/hkhub/utils/hooks'

import * as St from './NavDrawer.styles'

const NavLink = props => {
  return (
    <ClassNames>
      {({ css }) => (
        <RouterNavLink
          {...props}
          activeClassName={css(St.navLinkActive)}
          className={css(St.navLink)}
        />
      )}
    </ClassNames>
  )
}

export enum NavDrawerTestIds {
  links = 'NavDrawer__links',
}

type PickedDrawerStateProps = Pick<
  UseManageDrawerState,
  'drawerState' | 'closeDrawer' | 'completeDrawerClose'
>

export type NavDrawerProps = {
  initializingZone: boolean
  renderContainer?: Element
  setZoneSearchOpen: (val: boolean) => void
  zone?: Zone
  zoneSearchOpen: boolean
} & PickedDrawerStateProps

export const NavDrawer: React.FC<NavDrawerProps> = ({
  closeDrawer,
  completeDrawerClose,
  drawerState,
  initializingZone,
  renderContainer,
  setZoneSearchOpen,
  zone,
  zoneSearchOpen,
}) => {
  const { t, tp } = useI18n()
  const notificationCount = useSelector(getAllNotificationsCount)
  const inputRef = React.useRef<HTMLInputElement>(null)

  const { getPageUrl } = useHubRouting()

  const focusInput = () => {
    // This will only work if zoneSearchOpen is true
    inputRef.current?.focus()
  }

  React.useEffect(() => {
    if (zoneSearchOpen) focusInput()
  }, [zoneSearchOpen])

  return (
    <St.StyledDrawer
      {...drawerState}
      afterExit={completeDrawerClose}
      afterOpen={focusInput}
      renderContainer={renderContainer}
    >
      <St.DrawerContents>
        <St.ZoneSelectorWrapper>
          {zoneSearchOpen ? (
            <St.ZoneSelector
              isDisabled={initializingZone}
              onBlur={() => setZoneSearchOpen(false)}
              onZoneSelectionChange={closeDrawer}
              ref={inputRef}
              selectedZone={zone}
            />
          ) : (
            <SelectedZoneDisplay
              onChange={() => setZoneSearchOpen(true)}
              zone={zone}
            />
          )}
        </St.ZoneSelectorWrapper>

        {!!zone && (
          <St.NavLinksWrapper data-testid={NavDrawerTestIds.links}>
            <li>
              <NavLink onClick={closeDrawer} to={getPageUrl('schedule')}>
                {startCase(t(Slugs.schedule))}
              </NavLink>
            </li>

            <li>
              <NavLink onClick={closeDrawer} to={getPageUrl('housekeepers')}>
                {startCase(tp(Slugs.housekeeper, 2, false))}
              </NavLink>
            </li>

            <li>
              <NavLink onClick={closeDrawer} to={getPageUrl('manage')}>
                <St.TabWithNotification>
                  {startCase(t(Slugs.zone))}
                  {!!notificationCount && (
                    <NotificationBadge count={notificationCount} />
                  )}
                </St.TabWithNotification>
              </NavLink>
            </li>
          </St.NavLinksWrapper>
        )}

        <St.DrawerVersionDisplay />

        <St.Buttons>
          <Button
            buttonSize={'small'}
            buttonType={'utilityInverted'}
            onClick={logoutWithOneLogin}
          >
            {t(Slugs.logout)}
          </Button>
        </St.Buttons>
      </St.DrawerContents>
    </St.StyledDrawer>
  )
}
