import classNames from 'classnames'
import * as React from 'react'

import { TabTypes } from '../TabbedContent'

import styles from './Tab.module.scss'

export type TabProps = {
  /** Whether this is the current active tab */
  active?: boolean
  children: React.ReactNode
  dataTestId?: string
  /** Click handler for when tab is clicked */
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  /** The title to display on the tab itself */
  title: React.ReactNode
  /** Enum value determining the style of the tabs*/
  type?: TabTypes
}

const Tab: React.FunctionComponent<TabProps> = ({
  active = false,
  dataTestId = '',
  onClick,
  title,
  type = TabTypes.DEFAULT,
}) => (
  <div
    className={classNames(styles.tab, styles[type], {
      [styles.active]: active,
    })}
    data-testid={dataTestId}
    onClick={onClick}
  >
    {title}
    <span className={classNames(styles.hoverBorder, styles[type])} />
  </div>
)

export { Tab as UnwrappedTab }
/**
 * A Tab component, intended for use in the TabbedContent wrapper.
 *
 * Note that a Tab's `children` are never rendered by the Tab itself; they are rendered by the
 * `TabbedContent` parent component when it determines that a given Tab is the active one.
 */
export default React.memo(Tab)
