import { createSelector } from 'reselect'

import { ApplicationState } from 'app/hkhub/store/store'

import { CleanDrawerState } from '../ui.types'

export const getCleanDrawerState = createSelector(
  (state: ApplicationState) => state.ui.drawers.cleanDrawer,
  (cleanDrawerState: CleanDrawerState) => cleanDrawerState,
)
