import { always, noop } from 'lodash/fp'
import * as React from 'react'

import {
  UnitFilterBuckets,
  UnitFilterType,
  UnitViewFilter,
} from './UnitFilters.types'

// we can't have empty filters, so this will always serve as the default (i.e. effectively "no filter")
export const emptyFilter: UnitViewFilter[] = [
  {
    process: always(true),
    toString: always(''),
    toUiLabel: always(''),
  },
]

export const emptyFilterBuckets = {
  [UnitFilterType.Occupancy]: emptyFilter,
  [UnitFilterType.UnitStatus]: emptyFilter,
}

export type UnitFiltersContextType = {
  addFilters: (filterType: UnitFilterType, newFilters: UnitViewFilter[]) => void
  clearAllFilters: () => void
  clearSearchFilters: () => void
  disableNeedToClearSearchField: () => void
  filters: UnitFilterBuckets
  needToClearSearchField: boolean
  removeFilters: (
    filterType: UnitFilterType,
    filtersToRemove: UnitViewFilter[],
  ) => void
  resetToDefaultState: () => void
  searchFilters: UnitViewFilter[]
  setSearchFilters: (newFilters: UnitViewFilter[]) => void
}

export const UnitFiltersContext = React.createContext<UnitFiltersContextType>({
  addFilters: noop,
  clearAllFilters: noop,
  clearSearchFilters: noop,
  disableNeedToClearSearchField: noop,
  filters: emptyFilterBuckets,
  needToClearSearchField: false,
  removeFilters: noop,
  resetToDefaultState: noop,
  searchFilters: emptyFilter,
  setSearchFilters: noop,
})

UnitFiltersContext.displayName = 'UnitFiltersContext'

export const useUnitFiltersContext = (): UnitFiltersContextType =>
  React.useContext(UnitFiltersContext)
