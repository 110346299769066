import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { VirtualizedStaffScheduleContainer } from 'app/hkhub/components/schedule/components/staff/VirtualizedStaffSchedule'
import { VirtualizedUnitScheduleContainer } from 'app/hkhub/components/schedule/components/units/VirtualizedUnitSchedule'
import { useScheduleMatchParams } from 'app/hkhub/components/schedule/hooks'
import { useTrackSearchFunctionality } from 'app/hkhub/components/schedule/hooks/useTrackSearchFunctionality'
import {
  setCleanDrawerState,
  setVisitDrawerState,
} from 'app/hkhub/store/ui/actions'
import { getCleanDrawerState } from 'app/hkhub/store/ui/selectors/getCleanDrawerState'
import { getVisitDrawerState } from 'app/hkhub/store/ui/selectors/getVisitDrawerState'

import { CleanDrawerContainer } from '../CleanDrawer'
import { DateRangeHeader } from '../DateRangeHeader'
import { FilterControls } from '../FilterControls'
import { FloatingRangeMenuContainer as FloatingRangeMenu } from '../FloatingRangeMenu'
import { VisitDrawerContainer } from '../VisitDrawer/VisitDrawer.container'
import {
  ScheduleContext,
  ScheduleContextType,
} from './VirtualizedSchedule.context'

export type VirtualizedScheduleProps = {
  dateRange: Date[]
  isLoading: boolean
}

export const VirtualizedSchedule: React.FC<VirtualizedScheduleProps> = ({
  dateRange,
  isLoading = false,
}) => {
  const dispatch = useDispatch()

  useTrackSearchFunctionality()
  const { entity } = useScheduleMatchParams()
  const isUnitSchedule = entity === 'unit'

  const cleanDrawerState = useSelector(getCleanDrawerState)
  const visitDrawerState = useSelector(getVisitDrawerState)

  const handleCleanDrawerAfterExit = React.useCallback(() => {
    dispatch(setCleanDrawerState({ isOpen: false }))
  }, [dispatch])

  const handleVisitDrawerAfterExit = React.useCallback(() => {
    dispatch(setVisitDrawerState({ isOpen: false }))
  }, [dispatch])

  const scheduleContextValue: ScheduleContextType = React.useMemo(
    () => ({
      dateRange,
      isLoading,
    }),
    [dateRange, isLoading],
  )

  return (
    <>
      <CleanDrawerContainer
        cleanId={cleanDrawerState.cleanId}
        isOpen={cleanDrawerState.isOpen}
        onAfterExit={handleCleanDrawerAfterExit}
      />

      <VisitDrawerContainer
        visitId={visitDrawerState.visitId}
        isOpen={visitDrawerState.isOpen}
        onAfterExit={handleVisitDrawerAfterExit}
      />

      <div>
        <ScheduleContext.Provider value={scheduleContextValue}>
          <FilterControls />
          <DateRangeHeader />
          {isUnitSchedule ? (
            <VirtualizedUnitScheduleContainer />
          ) : (
            <VirtualizedStaffScheduleContainer dateRange={dateRange} />
          )}
          <FloatingRangeMenu />
        </ScheduleContext.Provider>
      </div>
    </>
  )
}
