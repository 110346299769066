import { createSelector } from 'reselect'

import { HousekeepersState, Housekeeper } from '../housekeepers.types'
import { getHousekeeperById } from './getHousekeeperById'

/**
 * Returns all housekeepers (cached via reselect) should recompute if state changes
 *
 * @param state
 */
export const getHousekeepers = createSelector(
  (state: HousekeepersState): string[] => Object.keys(state.data),
  (state: HousekeepersState) => state,
  (housekeeperIds, state): Housekeeper[] =>
    housekeeperIds
      .map(id => getHousekeeperById(state, id))
      .filter(hk => !!hk) as Housekeeper[],
)
