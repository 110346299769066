import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { isBefore, startOfToday } from 'packages/utils/dateHelpers'

import { ApplicationState } from 'app/hkhub/store/store'
import { Ticket } from 'app/hkhub/store/tickets'
import { setTicketDrawerState } from 'app/hkhub/store/ui/actions/setTicketDrawerState'
import { getUserById } from 'app/hkhub/store/users/selectors/getUserById'

import { CardSize } from '../../../helpers/scheduleMeasure'
import { TicketCard } from './TicketCard'

export type TicketCardContainerProps = {
  showIsOverdue?: boolean
  size: CardSize
  ticket: Ticket
}

export const TicketCardContainer: React.FC<TicketCardContainerProps> =
  React.memo(({ size, showIsOverdue = true, ticket }) => {
    const dispatch = useDispatch()
    const assignee = useSelector((state: ApplicationState) =>
      getUserById(state, ticket?.assigneeId || ''),
    )

    const handleOpenTicketDrawer = React.useCallback(() => {
      dispatch(setTicketDrawerState({ isOpen: true, ticketId: ticket.id }))
    }, [dispatch, ticket.id])

    const isOverdue = useMemo(() => {
      if (!showIsOverdue) return undefined
      return ticket?.dueDate ? isBefore(ticket.dueDate, startOfToday()) : false
    }, [showIsOverdue, ticket?.dueDate])

    return ticket ? (
      <TicketCard
        assignee={assignee}
        isOverdue={isOverdue}
        onClick={handleOpenTicketDrawer}
        size={size}
        ticket={ticket}
      />
    ) : null
  })
