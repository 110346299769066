import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect, Switch } from 'react-router-dom'

import { getLastKnownZone } from 'app/hkhub/store/zones/selectors'

import { FullPageZoneSelector } from './FullPageZoneSelector'
import { ZonePageContainer } from './ZonePage/ZonePage.container'

export const ZoneRouter: React.FC = () => {
  const lastZoneId = useSelector(getLastKnownZone)

  return (
    <Switch>
      <Route path="/zone/:zoneId" component={ZonePageContainer} />
      <Route path="/zone" exact={true} component={FullPageZoneSelector} />
      <Route path="/" exact={true} component={FullPageZoneSelector} />
      {lastZoneId && <Redirect to={`/zone/${lastZoneId}`} />}
      <Redirect to="/" />
    </Switch>
  )
}
