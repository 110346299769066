import styled from '@emotion/styled'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from 'packages/common'

import { Slugs, useI18n } from 'app/hkhub/i18n'

const St = {
  CenteredContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
  `,
}

const ZoneNotActive: React.FC = () => {
  const { t } = useI18n()
  const history = useHistory()

  const handleReturnHome = () => {
    history.push('/')
  }

  return (
    <St.CenteredContainer>
      <h1>{t(Slugs.zoneIsNotActive)}</h1>
      <Button onClick={handleReturnHome} buttonType="primary">
        {t(Slugs.returnToHomePage)}
      </Button>
    </St.CenteredContainer>
  )
}

export default ZoneNotActive
