import React from 'react'

import { useAdminAlerts } from '../../hooks/useAdminAlerts'
import { AdminContext } from './Admin.context'

export const AdminContextWrapper: React.FC = ({ children }) => {
  const { addAlert, alerts, removeAlert } = useAdminAlerts()

  const contextValue = React.useMemo(() => {
    return {
      addAlert,
      alerts,
      removeAlert,
    }
  }, [addAlert, alerts, removeAlert])

  return (
    <AdminContext.Provider value={contextValue}>
      {children}
    </AdminContext.Provider>
  )
}
