import styled from '@emotion/styled'
import React from 'react'
import { useDispatch } from 'react-redux'

import { Alert, Button, DatePicker, useToast } from 'packages/common'
import { text } from 'packages/styles'
import {
  createDateObject,
  DateFormat,
  formatLocalized,
  isSameDay,
} from 'packages/utils/dateHelpers'
import { useAsyncFnWithReset } from 'packages/utils/hooks'
import { track } from 'packages/wiretap'
import { SegmentEvents } from 'packages/wiretap/src/tracking/events'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Ticket } from 'app/hkhub/store/tickets'
import { updateTicket } from 'app/hkhub/store/tickets/actions/updateTicket'

import { TicketSection, TicketSectionTitle } from '../TicketDrawer.styles'

const St = {
  EditButtonContainer: styled.div`
    display: flex;
    justify-content: flex-end;
  `,
  EditingButtons: styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  `,
  EditingContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
  Section: styled(TicketSection)`
    position: relative;
  `,
  SubTitle: TicketSectionTitle,
  Text: styled.div`
    ${text.bodyRegularDefault};
  `,
}

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    cancel: ut(Slugs.cancel),
    dueDate: ut(Slugs.dueDate),
    editDueDate: `${ut(Slugs.edit)} ${ut(Slugs.dueDate)}`,
    failure: ut(Slugs.ticketUpdateFailure),
    save: ut(Slugs.save),
    success: ut(Slugs.ticketUpdateSuccess),
    unknown: ut(Slugs.unknown),
  }
}

export enum DateSectionTestIds {
  container = 'DateSection__container',
}

export type DateSectionProps = {
  ticket: Ticket
}

export const DateSection: React.FC<DateSectionProps> = React.memo(
  ({ ticket }) => {
    const strings = useTranslations()
    const dispatch = useDispatch()
    const { showToast } = useToast()

    const dueText = ticket.dueDateNoTimestamp
      ? formatLocalized(
          ticket.dueDateNoTimestamp,
          DateFormat.MonthShortDateAndYear,
        )
      : strings.unknown

    const [dueDate, setDueDate] = React.useState<Date | undefined>(
      ticket.dueDate ? createDateObject(ticket.dueDate) : undefined,
    )

    const [editing, setEditing] = React.useState(false)

    const onSuccess = React.useCallback(() => {
      showToast({ message: strings.success })
      setEditing(false)
      track(SegmentEvents.hubTicketDueDateUpdate)
    }, [showToast, strings.success])

    const [updateState, updateTicketFn, resetUpdateState] =
      useAsyncFnWithReset(async () => {
        return dispatch(
          updateTicket(
            {
              dueDate: dueDate
                ? formatLocalized(
                    dueDate,
                    DateFormat.ApiUtcWithSeconds,
                    'America/Los_Angeles',
                  )
                : undefined,
              id: ticket.id,
            },
            { onSuccess },
          ),
        )
      }, [dispatch, dueDate, onSuccess, ticket.id])

    const editable = !ticket.completedAt

    const disableSubmit =
      !dueDate ||
      isSameDay(dueDate, createDateObject(ticket.dueDate)) ||
      updateState.loading ||
      !!updateState.error

    const onEdit = () => {
      setEditing(true)
    }

    const onCancel = () => {
      setEditing(false)
      setDueDate(createDateObject(ticket.dueDate))
    }

    return (
      <St.Section>
        {editing ? (
          <St.EditingContainer>
            {ticket.dueDate && (
              <DatePicker
                onDateChange={(date: Date | undefined) => {
                  setDueDate(date)
                }}
                selectedDate={dueDate}
              />
            )}

            {!!updateState.error && (
              <Alert alertType={'danger'} onClose={resetUpdateState}>
                <span>{strings.failure}</span>
              </Alert>
            )}

            <St.EditingButtons>
              <Button
                buttonType={'utility'}
                disabled={updateState.loading}
                onClick={onCancel}
              >
                {strings.cancel}
              </Button>
              <Button
                disabled={disableSubmit}
                isLoading={updateState.loading}
                onClick={updateTicketFn}
              >
                {strings.save}
              </Button>
            </St.EditingButtons>
          </St.EditingContainer>
        ) : (
          <>
            <St.SubTitle>{strings.dueDate}</St.SubTitle>
            <St.Text>{dueText}</St.Text>
            {editable && (
              <St.EditButtonContainer>
                <Button onClick={onEdit} buttonType={'text'}>
                  {strings.editDueDate}
                </Button>
              </St.EditButtonContainer>
            )}
          </>
        )}
      </St.Section>
    )
  },
)
