import styled from '@emotion/styled'
import React from 'react'
import { useSelector } from 'react-redux'

import { CleanCardContainer } from 'app/hkhub/components/schedule/components/common/CleanCard'
import { useScheduleContext } from 'app/hkhub/components/schedule/components/common/VirtualizedSchedule/VirtualizedSchedule.context'
import { ScheduleDayCleansContainer } from 'app/hkhub/components/schedule/components/schedule.styles'
import {
  CardSize,
  getUnitCellMinHeight,
  useCurrentCardSize,
} from 'app/hkhub/components/schedule/helpers/scheduleMeasure'
import { Clean } from 'app/hkhub/store/cleans'
import { Reservation } from 'app/hkhub/store/reservations/reservations.types'
import { Ticket } from 'app/hkhub/store/tickets'
import { getLoadingCleanId } from 'app/hkhub/store/ui/selectors'

import { DateCellLoader } from '../../../common/DateCellLoader'
import { TicketCardContainer } from '../../../common/TicketCard/TicketCard.container'
import { ReservationsGutter } from '../../ReservationsGutter'

const St = {
  UnitScheduleCleans: styled(ScheduleDayCleansContainer)<{
    cardSize: CardSize
  }>`
    min-height: ${({ cardSize }) => getUnitCellMinHeight(cardSize)}px;
  `,
}

export type UnitScheduleEventsProps = {
  cardSize: CardSize
  cleans: Clean[]
  date: Date
  reservations: Reservation[]
  tickets: Ticket[]
}

export const UnitScheduleEvents: React.FC<UnitScheduleEventsProps> = ({
  cardSize,
  cleans,
  date,
  reservations = [],
  tickets = [],
}) => {
  const loadingCleanId = useSelector(getLoadingCleanId)
  const { dateRange, isLoading } = useScheduleContext()
  const size = useCurrentCardSize()

  return (
    <St.UnitScheduleCleans cardSize={cardSize} dayCount={dateRange.length}>
      <ReservationsGutter date={date} reservations={reservations} />

      {isLoading ? (
        <DateCellLoader cardType={'unit'} size={cardSize} />
      ) : (
        <>
          {cleans.map(clean => (
            <CleanCardContainer
              clean={clean}
              isLoading={loadingCleanId === clean.id}
              key={clean.id}
            />
          ))}
          {tickets.map(ticket => (
            <TicketCardContainer
              size={size}
              key={ticket.id}
              showIsOverdue={false}
              ticket={ticket}
            />
          ))}
        </>
      )}
    </St.UnitScheduleCleans>
  )
}
