import { createSelector } from 'reselect'

import { ApplicationState } from 'app/hkhub/store/store'

import { TicketDrawerState } from '../ui.types'

export const getTicketDrawerState = createSelector(
  (state: ApplicationState) => state.ui.drawers.ticketDrawer,
  (ticketDrawerState: TicketDrawerState) => ticketDrawerState,
)
