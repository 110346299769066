import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import { withMaintenanceMode } from 'packages/common'
import { SegmentTracker } from 'packages/wiretap/src/tracking/SegmentTracker'

import { AdminPage } from './admin/AdminPage'
import { NotFoundPage } from './core/NotFoundPage'
import { ZoneRouter } from './zone/Zone.router'

export type HkHubRootProps = {
  initialUrl: string
}

export const HkHubRoot: React.FC<HkHubRootProps> = ({ initialUrl }) => (
  <BrowserRouter>
    <Switch>
      <Route component={AdminPage} path="/admin" />
      <Route component={ZoneRouter} path="/zone" />
      <Redirect from="/oauth/callback" to={initialUrl || '/zone'} />
      <Redirect from="/" to="/zone" />
      <Route component={NotFoundPage} />
    </Switch>
    <SegmentTracker />
  </BrowserRouter>
)

export default withMaintenanceMode(HkHubRoot)
