import { deprecated } from 'typesafe-actions'

import { AuthActionTypes } from '../auth.types'

const { createStandardAction } = deprecated

type Tokens = { idToken: string | undefined; refreshToken: string | undefined }

export const setTokensAction = createStandardAction(
  AuthActionTypes.SET_TOKENS,
)<Tokens>()

export const setTokens = (tokens: Tokens) => dispatch =>
  dispatch(setTokensAction(tokens))
