import {
  allPass,
  anyPass,
  concat,
  map,
  pipe,
  prop,
  reduce,
  sortBy,
  toPairs,
  toLower,
  toString,
  values,
} from 'lodash/fp'

import {
  UnitViewFilter,
  UnitCleanFilterData,
  UnitFilterBuckets,
  UnitFilterType,
} from 'app/hkhub/components/schedule/components/units/contexts/UnitFiltersContext'
import { UnitCleanSchedule } from 'app/hkhub/store/cleans/cleans.types'
import { Reservation } from 'app/hkhub/store/reservations/reservations.types'
import { UnitByIdMap } from 'app/hkhub/store/units/units.types'

const lowercaseUnitCode = pipe(prop('unitCode'), toLower)
const idAsString = pipe(prop('id'), toString)

/**
 * Takes a map of {Unit IDs : Units}, and returns an Array of the Unit IDs sorted by the 'unitCode' field.
 */
export const getSortedIdsFromUnitMap: (unitsMap: UnitByIdMap) => string[] =
  pipe(values, sortBy([lowercaseUnitCode]), map(idAsString))

type findFilteredUnitIdsArgs = {
  activeUnitsMap: UnitByIdMap
  filters: UnitFilterBuckets
  reservationsByUnit: {
    [unitId: string]: Reservation[]
  }
  searchFilters: UnitViewFilter[]
  unfilteredUnitRows: UnitCleanSchedule
}

/**
 * Returns a flat list of Unit IDs that pass all current filters.
 */
export const findFilteredUnitIds = ({
  activeUnitsMap,
  filters,
  reservationsByUnit,
  searchFilters,
  unfilteredUnitRows,
}: findFilteredUnitIdsArgs): string[] => {
  // maps the pairs of unitId-to-cleans to full unit-to-cleans pairs
  // we might have cleans tied to inactive units at this point, so we need to ensure we are filtering these out
  const makeDataForActiveUnits = (
    acc: UnitCleanFilterData[],
    [unitId, cleans],
  ) => {
    const unit = activeUnitsMap[unitId]
    if (unit?.id) {
      const reservations = reservationsByUnit[unit.id] ?? []
      acc.push({ cleans, reservations, unit })
    }

    return acc
  }

  const filterFns = map(prop('process'))

  // processes all current filters against the full units-to-cleans list,
  // and return an Array of just the Unit IDs that pass the filters
  const getFilteredUnitIds = (acc: string[], data: UnitCleanFilterData) => {
    // OR summing for search
    const passesSearch = anyPass(filterFns(searchFilters))

    // OR summing for occupancy
    const passesOccupancyFilters = anyPass(
      filterFns(filters[UnitFilterType.Occupancy]),
    )

    // OR summing for unit status
    const passesUnitStatusFilters = anyPass(
      filterFns(filters[UnitFilterType.UnitStatus]),
    )

    // AND summing for the statuses of each type from above
    // i.e. a Unit must pass all of the OR-summed filters
    const pass = allPass([
      passesSearch,
      passesOccupancyFilters,
      passesUnitStatusFilters,
    ])(data)

    return concat(pass ? `${data.unit.id}` : [])(acc)
  }

  return pipe(
    toPairs,
    reduce(makeDataForActiveUnits, []),
    reduce(getFilteredUnitIds, []),
  )(unfilteredUnitRows)
}
