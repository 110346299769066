import { useSelector } from 'react-redux'

import { ApplicationState } from 'app/hkhub/store/store'
import { getSortedTicketCommentIds } from 'app/hkhub/store/ticketComments/selectors'
import { getTicketById } from 'app/hkhub/store/tickets/selectors'
import { getUnitById } from 'app/hkhub/store/units/selectors'
import { getUserById } from 'app/hkhub/store/users/selectors'

export type UseTicket = {
  assignee: ReturnType<typeof getUserById>
  sortedTicketCommentIds: ReturnType<typeof getSortedTicketCommentIds>
  ticket: ReturnType<typeof getTicketById>
  unit: ReturnType<typeof getUnitById>
}

export const useTicket = (ticketId: string): UseTicket => {
  const ticket = useSelector((state: ApplicationState) =>
    getTicketById(state, ticketId),
  )

  const sortedTicketCommentIds = useSelector((state: ApplicationState) =>
    getSortedTicketCommentIds(state, ticket?.commentIds || []),
  )

  const assignee = useSelector((state: ApplicationState) =>
    getUserById(state, ticket?.assigneeId || ''),
  )

  const unit = useSelector((state: ApplicationState) =>
    getUnitById(state.units, ticket?.unitId || ''),
  )

  return { assignee, sortedTicketCommentIds, ticket, unit }
}
