import styled from '@emotion/styled'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Alert, Button, Select, useToast } from 'packages/common'
import { fullName } from 'packages/grimoire'
import { useI18n } from 'packages/i18n'
import { text } from 'packages/styles'
import { useAsyncFnWithReset } from 'packages/utils/hooks'
import { track } from 'packages/wiretap'
import { SegmentEvents } from 'packages/wiretap/src/tracking/events'

import { Slugs } from 'app/hkhub/i18n'
import { UnknownHk } from 'app/hkhub/store/housekeepers/housekeepers.utils'
import { getSortedHks } from 'app/hkhub/store/housekeepers/selectors'
import { ApplicationState } from 'app/hkhub/store/store'
import { updateTicket } from 'app/hkhub/store/tickets/actions/updateTicket'
import { User } from 'app/hkhub/store/users'
import { getUserById } from 'app/hkhub/store/users/selectors'

import { TicketSection, TicketSectionTitle } from '../TicketDrawer.styles'
import { useNonnullableTicket } from './helpers'

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    assignee: ut(Slugs.assignee),
    assignment: ut(Slugs.assignment),
    cancel: ut(Slugs.cancel),
    editAssignment: ut(Slugs.editAssignment),
    failure: ut(Slugs.ticketUpdateFailure),
    save: ut(Slugs.save),
    success: ut(Slugs.ticketUpdateSuccess),
    unassigned: ut(Slugs.unassigned),
  }
}

const St = {
  Assignee: styled.div`
    ${text.bodyRegularDefault};
    align-items: center;
    display: flex;
  `,
  EditButtonContainer: styled.div`
    display: flex;
    justify-content: flex-end;
  `,
  EditingButtons: styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  `,
  EditingContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
  Section: styled(TicketSection)`
    position: relative;
  `,
  SubTitle: TicketSectionTitle,
}

export enum AssignmentSectionTestIds {
  container = 'AssignmentSection__container',
}

export type AssignmentSectionProps = {
  ticketId: string
}

export const AssignmentSection: React.FC<AssignmentSectionProps> = React.memo(
  ({ ticketId }) => {
    const strings = useTranslations()
    const dispatch = useDispatch()
    const { showToast } = useToast()

    const { assignee, ticket } = useNonnullableTicket(ticketId)
    const housekeepers = useSelector((state: ApplicationState) =>
      getSortedHks(state.housekeepers),
    )

    const users = useMemo(() => housekeepers.map(hk => hk.user), [housekeepers])

    const initialAssignee =
      useSelector((state: ApplicationState) =>
        getUserById(state, ticket?.assigneeId || ''),
      ) || UnknownHk.user

    const [selectedAssignee, setSelectedAssignee] =
      React.useState<User>(initialAssignee)

    const [editing, setEditing] = React.useState(false)

    const onSuccess = React.useCallback(() => {
      showToast({ message: strings.success })
      setEditing(false)
      track(SegmentEvents.hubTicketAssignmentUpdate)
    }, [showToast, strings.success])

    const [updateState, updateTicketFn, resetUpdateState] =
      useAsyncFnWithReset(async () => {
        return dispatch(
          updateTicket(
            {
              assigneeId: selectedAssignee.id,
              id: ticket.id,
            },
            { onSuccess },
          ),
        )
      }, [dispatch, onSuccess, ticket.id, selectedAssignee.id])

    const disableSubmit =
      !selectedAssignee ||
      selectedAssignee.id === ticket.assigneeId ||
      updateState.loading ||
      !!updateState.error

    const editable = !ticket.completedAt

    const assigneeText = assignee ? fullName(assignee) : strings.unassigned

    const onEdit = () => {
      setEditing(true)
    }

    const onCancel = () => {
      setEditing(false)
      setSelectedAssignee(initialAssignee)
    }

    return (
      <St.Section>
        {editing ? (
          <St.EditingContainer>
            <Select
              disabled={!!updateState.error || updateState.loading}
              fullWidth={true}
              getOptionLabel={fullName}
              onChange={user => setSelectedAssignee(user)}
              options={users}
              placeholder={strings.assignee}
              selectedValue={selectedAssignee}
            />
            {!!updateState.error && (
              <Alert alertType={'danger'} onClose={resetUpdateState}>
                <span>{strings.failure}</span>
              </Alert>
            )}

            <St.EditingButtons>
              <Button
                buttonType={'utility'}
                disabled={updateState.loading}
                onClick={onCancel}
              >
                {strings.cancel}
              </Button>
              <Button
                disabled={disableSubmit}
                isLoading={updateState.loading}
                onClick={updateTicketFn}
              >
                {strings.save}
              </Button>
            </St.EditingButtons>
          </St.EditingContainer>
        ) : (
          <>
            <St.SubTitle>{strings.assignment}</St.SubTitle>
            <St.Assignee>{assigneeText}</St.Assignee>

            {editable && (
              <St.EditButtonContainer>
                <Button onClick={onEdit} buttonType={'text'}>
                  {strings.editAssignment}
                </Button>
              </St.EditButtonContainer>
            )}
          </>
        )}
      </St.Section>
    )
  },
)
