import { transformNormalizedToTyped } from 'packages/utils/store'

import { ApplicationState } from '../../store'
import { UnitOncallUser } from '../units.types'

export const getOncallUserForUnit = (
  state: ApplicationState,
  unitId: string,
): UnitOncallUser | undefined => {
  const oncallUserId =
    state.units.data?.[unitId]?.relationships?.oncallUser?.data?.id

  if (!oncallUserId) {
    return undefined
  }

  const rawOncallUser = state.units.oncallUsers?.[oncallUserId]

  if (!rawOncallUser) {
    return undefined
  }

  return transformNormalizedToTyped<UnitOncallUser>(rawOncallUser, [
    'firstName',
    'lastName',
    'reasonForAssignment',
    'mobilePhone',
  ])
}
