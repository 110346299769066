import styled from '@emotion/styled'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ValueType } from 'react-select/src/types'

import { AsyncSelector } from 'packages/common'
import { AsyncSelectorTextStyle } from 'packages/common/src/AsyncSelector/AsyncSelector'
import { layers, colors } from 'packages/styles'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { ApplicationState } from 'app/hkhub/store/store'
import {
  clearZone,
  searchZones,
  selectZone,
} from 'app/hkhub/store/zones/actions'
import {
  getPreviouslySelectedZones,
  getZoneSearchResults,
} from 'app/hkhub/store/zones/selectors'
import { Zone } from 'app/hkhub/store/zones/zones.types'

import { getHighlightedZoneSearchResults } from './ZoneSelector.helpers'

const St = {
  ZoneSelector: styled(AsyncSelector)`
    min-width: 250px;
    z-index: ${layers.zoneSelector};

    // This targets the singleValue element in the asyncSelector, when the selector is open
    div.asyncSelector__control--is-focused .asyncSelector__single-value {
      color: ${colors.midnight};
    }
  `,
}

// builds the Promise required for loading search results in the react-select "loadOptions" prop
const useZoneSearchLoader = () => {
  const dispatch = useDispatch()
  const getSearchResults = useSelector(
    (state: ApplicationState) => () => getZoneSearchResults(state.zones),
  )

  const getSearchResultsRef = React.useRef(getSearchResults)
  getSearchResultsRef.current = getSearchResults

  return (input: string): Promise<Zone[]> =>
    new Promise(async (resolve, reject) => {
      try {
        await dispatch(searchZones(input))
        resolve(getSearchResultsRef.current())
      } catch (e) {
        reject([])
      }
    })
}

export type ZoneSelectorProps = {
  className?: string
  isDisabled?: boolean
  onBlur?: () => void
  onZoneSelectionChange?: (value: ValueType<Zone>) => void
  selectedZone?: Zone
}

export const ZoneSelector = React.memo(
  React.forwardRef<HTMLInputElement, ZoneSelectorProps>(
    (
      {
        className,
        isDisabled = false,
        onBlur,
        onZoneSelectionChange,
        selectedZone,
      },
      ref,
    ) => {
      const { t } = useI18n()
      const dispatch = useDispatch()
      const history = useHistory()
      const zonesLoader = useZoneSearchLoader()

      const handleGetPreviouslySelectedZones = useSelector(
        (state: ApplicationState) => () =>
          getPreviouslySelectedZones(state.zones),
      )

      // store our current zone in local state
      // this is USUALLY the same as the prop, but since the prop does not update until after the zone has fully loaded,
      // there can be a small window where this selector still shows the old zone name
      // we will always favor the prop, but in the time between selecting a new zone and when the prop updates, we will just set the zone directly internally
      const [zone, setZone] = React.useState(() => selectedZone)

      // use the prop anytime it actually updates
      React.useEffect(() => {
        setZone(selectedZone)
      }, [selectedZone])

      const handleZoneChange = React.useCallback(
        value => {
          // use the new selection as our selected zone until the updated prop has a chance to propogate
          setZone(value)

          if (value && !Array.isArray(value)) {
            dispatch(clearZone())
            dispatch(selectZone(value))
            history.push(`/zone/${(value as Zone).id}`)
          }

          if (onZoneSelectionChange) onZoneSelectionChange(value)
        },
        [dispatch, history, onZoneSelectionChange],
      )

      const noOptionsMessage = React.useCallback(
        ({ inputValue }) =>
          inputValue === '' ? t(Slugs.typeToSearch) : t(Slugs.noResults),
        [t],
      )

      return (
        <St.ZoneSelector
          className={className}
          defaultOptions={handleGetPreviouslySelectedZones()}
          formatOptionLabel={getHighlightedZoneSearchResults}
          getOptionValue={zone => zone.id}
          isDisabled={isDisabled}
          loadingMessage={() => t(Slugs.loading)}
          loadOptions={zonesLoader}
          onBlur={onBlur}
          noOptionsMessage={noOptionsMessage}
          onChange={handleZoneChange}
          placeholder={`${t(Slugs.select)}...`}
          ref={ref}
          textStyle={AsyncSelectorTextStyle.WHITE}
          value={zone}
        />
      )
    },
  ),
)
