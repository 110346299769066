import React from 'react'
import { Route, Redirect, Switch, useRouteMatch } from 'react-router-dom'

import { parseISO } from 'packages/utils/dateHelpers'

import {
  ScheduleEntityType,
  ScheduleUrlSearchParams,
} from 'app/hkhub/components/schedule/schedule.types'
import { Zone } from 'app/hkhub/store/zones/zones.types'

import { useScheduleNavigation } from './hooks'
import { SchedulePage } from './SchedulePage'

type RedirectParams = ScheduleUrlSearchParams & {
  entity: ScheduleEntityType
  zoneId: string
}

/**
 * A simple redirect component to maintain backwards compatibility with our old URL schema.
 * This renders nothing, but simply does an immediate redirect re-mapping the old schema to the new.
 *
 * e.g.
 * FROM: /zone/{zoneId}/schedule/{date}/{viewType}/{entity}
 * TO:   /zone/{zoneId}/schedule/{entity}?date={date}&days={dayCount}
 *
 * Note that most of these are just moving params, with the exception of "viewType",
 * where before it was [day|week], it is now "days" as a simple numeric value.
 */
const LegacyScheduleRedirect = () => {
  const match = useRouteMatch<RedirectParams>()
  const {
    params: { date: dateParam, days: daysParam },
  } = match
  const { navigate } = useScheduleNavigation()

  const date = parseISO(dateParam)
  const dayCount = daysParam === 'day' ? 1 : 7

  navigate({ date, dayCount })
  return null
}

export type ScheduleRouterProps = {
  zone: Zone
}

export const ScheduleRouter: React.FC<ScheduleRouterProps> = props => {
  return (
    <Switch>
      <Route
        exact={true}
        path="/zone/:zoneId/schedule/:scheduleEntity"
        render={() => <SchedulePage {...props} />}
      />

      <Redirect
        exact={true}
        from="/zone/:zoneId/schedule"
        to={`/zone/:zoneId/schedule/staff`}
      />

      <Route
        component={LegacyScheduleRedirect}
        exact={true}
        path="/zone/:zoneId/schedule/:date/:days/:entity"
      />
    </Switch>
  )
}
