import styled from '@emotion/styled'
import { OptionType, StylesConfig } from 'react-select'

import { SvgIcon } from 'packages/iconic'
import { colors, text } from 'packages/styles'

import { SharedEditSectionStyles } from '../../CleanDrawer.styles'

export const St = {
  AddHousekeeper: styled.div`
    color: ${colors.lake};
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    margin-top: 8px;
    padding: 8px 0 0 0;
    width: max-content;
  `,

  AssignmentEditorContainer: styled.div`
    padding-left: 8px;
    padding-top: 8px;
    position: relative;
  `,

  Buttons: styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-end;
  `,

  HKAssignmentWrapper: styled.div`
    align-items: center;
    display: flex;
    width: 100%;

    & + & {
      margin-top: 4px;
    }
  `,

  HKRemoveButton: styled(SvgIcon)`
    color: ${colors.alert};
    cursor: pointer;
    height: 20px;
    margin-left: 8px;
    width: 20px;
  `,

  MissingRatesIcon: styled(SvgIcon)`
    color: ${colors.alert};
    margin-left: 8px;
  `,

  MissingRatesOptionText: styled.span`
    text-decoration: line-through;
  `,

  MissingRatesText: styled.span`
    ${text.bodyRegularSmall}
    color: ${colors.alert};
    font-style: italic;
    margin-bottom: 0;
    margin-left: 8px;
  `,

  RejectionReason: styled.span`
    ${text.bodyRegularSmall}
    color: ${colors.dusk40};
    font-style: italic;
    margin-bottom: 0;
    margin-left: 10px;
  `,

  ...SharedEditSectionStyles,
}

/**
 * These styles are specifically formatted to match the format that React-Select expects for custom styles
 * https://react-select.com/home#custom-styles
 */
type IsMulti = false

export const AssignmentSelectOptionStyles: StylesConfig<OptionType, IsMulti> = {
  option: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1

    return {
      ...provided,
      'align-items': 'center',
      display: 'flex',
      opacity,
    }
  },
  singleValue: provided => {
    return {
      ...provided,
      'align-items': 'center',
      display: 'flex',
    }
  },
}
