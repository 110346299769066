import {
  anyPass,
  allPass,
  curry,
  filter,
  includes,
  isEmpty,
  map,
  negate,
  pipe,
  prop,
  reject,
  toLower,
} from 'lodash/fp'

import { isWithinInterval } from 'packages/utils/dateHelpers'
import {
  format,
  isSameDay,
  formatLocalized,
  startOfDay,
  endOfDay,
  DateFormat,
} from 'packages/utils/dateHelpers'
import { flippedIncludes } from 'packages/utils/misc/array.helpers'

import { Slugs } from 'app/hkhub/i18n'
import {
  Reservation,
  ReservationBookingType,
} from 'app/hkhub/store/reservations/reservations.types'
import { Unit } from 'app/hkhub/store/units/units.types'

import { UnitViewFilter } from '../contexts'

export type UnitFilter = (unit: Unit) => boolean
type ReservationsFilter = (reservations: Reservation[]) => boolean

/**************************************************
 * Occupancy Filter Helpers
 **************************************************/

export enum OccupancyType {
  ARRIVAL = 'Arrival',
  ARRIVAL_PLUS_DEPARTURE = 'Arrival + Departure',
  DEPARTURE = 'Departure',
  STAYOVER = 'Stayover',
  VACANT = 'Vacant',
  VACASA_HOLD = 'Vacasa Hold',
}

export const OccupancySlugMap = {
  [OccupancyType.ARRIVAL]: Slugs.arrival,
  [OccupancyType.ARRIVAL_PLUS_DEPARTURE]: Slugs.arrivalAndDeparture,
  [OccupancyType.DEPARTURE]: Slugs.departure,
  [OccupancyType.STAYOVER]: Slugs.stayover,
  [OccupancyType.VACASA_HOLD]: Slugs.vacasaHold,
  [OccupancyType.VACANT]: Slugs.vacant,
}

/**************************************************
 * General Filter Helpers
 **************************************************/

type FilterOnReservations = (reservations: Reservation[]) => Reservation[]

export const filterForBookingTypes = (
  types: ReservationBookingType[],
): FilterOnReservations =>
  filter(
    pipe(prop('bookingType'), flippedIncludes<ReservationBookingType>(types)),
  )

/**
 * For a given date, returns all reservations where the reservation occurs on that date.
 * This includes reservations with check in/out dates that are the same as the date.
 * Note that this uses `startOfDay` and `endOfDay` on the reservation check in/out,
 * if a time comparison is needed, this is not an adequate function.
 * */
export const filterForOccurOnDate = (date: Date): FilterOnReservations =>
  filter(res => {
    try {
      const { checkIn, checkOut } = res
      return isWithinInterval(date, {
        end: endOfDay(checkOut),
        start: startOfDay(checkIn),
      })
    } catch (err) {
      return false
    }
  })

/** Returns the property at 'name' converted to lower case; use this for all search strings */
const lowerProp = (name: string) => pipe(prop(name), toLower)

/**************************************************
 * Unit Search Filters
 **************************************************/

/** Returns whether the provided string matches any part of the Unit's "name" property (case-insensitive) */
export const matchesUnitName = (searchStr: string): UnitFilter =>
  pipe(lowerProp('name'), includes(toLower(searchStr)))

/** Returns whether the provided string matches any part of the Unit's "unitCode" property (case-insensitive) */
export const matchesUnitCode = (searchStr: string): UnitFilter =>
  pipe(lowerProp('unitCode'), includes(toLower(searchStr)))

/**
 * Creates a new UnitViewFilter instance with the provided search string, which provides a
 * simple text search against the following properties on Unit:
 * - name
 * - unitCode
 */
export const makeUnitSearchFilter = (searchStr: string): UnitViewFilter => ({
  process: pipe(
    prop('unit'),
    anyPass([matchesUnitName(searchStr), matchesUnitCode(searchStr)]),
  ),
  toString: () => searchStr,
  toUiLabel: t => t(Slugs.unitSearch),
})

/**************************************************
 * Unit Occupancy Filters
 **************************************************/

/** Returns whether the provided date matches the checkOut for any reservation for a Unit */
const matchesDeparture = (date: Date): ReservationsFilter =>
  pipe(map(prop('checkOut')), filter(curry(isSameDay)(date)), negate(isEmpty))

/** Returns whether the provided date matches the checkIn for any reservation for a Unit */
const matchesArrival = (date: Date): ReservationsFilter =>
  pipe(map(prop('checkIn')), filter(curry(isSameDay)(date)), negate(isEmpty))

const matchesArrivalPlusDeparture = (date: Date): ReservationsFilter =>
  allPass([matchesDeparture(date), matchesArrival(date)])

const rejectDepartures = (date: Date) =>
  reject(pipe(prop('checkOut'), curry(isSameDay)(date)))

const rejectArrivals = (date: Date) =>
  reject(pipe(prop('checkIn'), curry(isSameDay)(date)))

const matchesStayover = (date: Date): ReservationsFilter =>
  pipe(
    filterForBookingTypes([
      ReservationBookingType.OWNER,
      ReservationBookingType.GUEST,
    ]),
    filterForOccurOnDate(date),
    rejectDepartures(date),
    rejectArrivals(date),
    negate(isEmpty),
  )

const matchesVacasaHold = (date: Date): ReservationsFilter =>
  pipe(
    filterForBookingTypes([ReservationBookingType.VACASA]),
    filterForOccurOnDate(date),
    negate(isEmpty),
  )

const matchesVacant = (date: Date): ReservationsFilter =>
  pipe(filterForOccurOnDate(date), isEmpty)

const OccupancyFilterMap = {
  [OccupancyType.ARRIVAL]: matchesArrival,
  [OccupancyType.ARRIVAL_PLUS_DEPARTURE]: matchesArrivalPlusDeparture,
  [OccupancyType.DEPARTURE]: matchesDeparture,
  [OccupancyType.STAYOVER]: matchesStayover,
  [OccupancyType.VACASA_HOLD]: matchesVacasaHold,
  [OccupancyType.VACANT]: matchesVacant,
}

export const makeUnitOccupancyFilter = (
  occupancy: OccupancyType,
  date: Date,
): UnitViewFilter => ({
  process: pipe(prop('reservations'), OccupancyFilterMap[occupancy](date)),
  toString: () =>
    `${format(date, DateFormat.SlashesWithShortYear)} - ${occupancy}`,
  toUiLabel: t =>
    `${formatLocalized(date, DateFormat.MonthAndDayShort)} - ${t(
      OccupancySlugMap[occupancy],
    )}`,
})
