import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAsyncFn, usePrevious } from 'react-use'

import { getLockboxByUnitId } from 'app/hkhub/store/lockboxes/selectors'
import { ApplicationState } from 'app/hkhub/store/store'
import { setUnitDrawerState } from 'app/hkhub/store/ui/actions'
import { getUnitDrawerState } from 'app/hkhub/store/ui/selectors'
import { fetchUnitByIdStrict } from 'app/hkhub/store/units/actions'
import { getUnitById } from 'app/hkhub/store/units/selectors'

import { UnitDrawer } from './UnitDrawer'

export const UnitDrawerContainer: React.FC = React.memo(() => {
  const dispatch = useDispatch()

  const { isOpen, unitId } = useSelector(getUnitDrawerState)
  const prevIsOpen = usePrevious(isOpen)

  const unit = useSelector((state: ApplicationState) =>
    getUnitById(state.units, unitId),
  )

  const lockbox = useSelector((state: ApplicationState) =>
    getLockboxByUnitId(state, unitId),
  )

  const handleAfterExit = React.useCallback(() => {
    dispatch(setUnitDrawerState({ isOpen: false, unitId: '' }))
  }, [dispatch])

  const handleClickAway = React.useCallback((_event: MouseEvent) => {
    return false
  }, [])

  const [_, fetchUnitFn] = useAsyncFn(async (unitId: string) => {
    return dispatch(fetchUnitByIdStrict(unitId))
  }, [])

  // re-fetch the unit every time the drawer is opened, to ensure we have up-to-date data
  React.useEffect(() => {
    const justOpened = isOpen && !prevIsOpen

    if (justOpened && unitId) {
      fetchUnitFn(unitId)
    }
  }, [fetchUnitFn, isOpen, prevIsOpen, unitId])

  return (
    <UnitDrawer
      forceCloseDrawer={false}
      handleAfterExit={handleAfterExit}
      handleClickAway={handleClickAway}
      isOpen={isOpen}
      lockbox={lockbox}
      unit={unit}
    />
  )
})
