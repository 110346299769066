import { produce } from 'immer'
import { ActionType, getType } from 'typesafe-actions'

import {
  setAuthErrorAction,
  setNeedsFullAuthRedirectAction,
  setNeedsSilentRefreshAction,
  setTokensAction,
} from './actions'
import { AuthState } from './auth.types'

export const initialState: AuthState = {
  needsFullAuthRedirect: true,
  needsSilentRefresh: false,
  refreshToken: undefined,
  token: undefined,
}

const actions = {
  setAuthErrorAction,
  setNeedsFullAuthRedirectAction,
  setNeedsSilentRefreshAction,
  setTokensAction,
}

type AuthActionsTypes = ActionType<typeof actions>

export const authReducer = (
  state = initialState,
  action: AuthActionsTypes,
): AuthState =>
  produce(state, (draft: AuthState) => {
    switch (action.type) {
      case getType(setTokensAction): {
        draft.error = undefined
        draft.needsFullAuthRedirect = false
        draft.needsSilentRefresh = false
        draft.refreshToken = action.payload.refreshToken
        draft.token = action.payload.idToken
        return
      }

      case getType(setNeedsFullAuthRedirectAction): {
        draft.error = undefined
        draft.needsFullAuthRedirect = action.payload
        draft.needsSilentRefresh = false
        return
      }

      case getType(setNeedsSilentRefreshAction): {
        draft.error = undefined
        draft.needsFullAuthRedirect = false
        draft.needsSilentRefresh = action.payload
        return
      }

      case getType(setAuthErrorAction): {
        draft.error = action.payload
        draft.needsFullAuthRedirect = false
        draft.needsSilentRefresh = false
        draft.token = undefined
        return
      }
    }
  })
