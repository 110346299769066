import styled from '@emotion/styled'
import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import { Alert, Loader } from 'packages/common'

import { Titlebar } from 'app/hkhub/components/core/components/Titlebar'
import { Zone } from 'app/hkhub/store/zones/zones.types'

import { NotFoundPage } from '../../core/NotFoundPage'
import HousekeepersContainer from '../../housekeepers/HousekeepersContainer/HousekeepersContainer'
import ZoneManageContainer from '../../manage/ZoneManageContainer/ZoneManageContainer'
import { ScheduleRouter } from '../../schedule/Schedule.router'

const St = {
  OfflineAlert: styled(Alert)`
    margin: 0;
  `,
}

export type ZonePageProps = {
  initializingZone: boolean
  isOnline: boolean
  zone?: Zone
  zoneInitialized: boolean
  zoneIsInvalid: boolean
}

export const ZonePage: React.FC<ZonePageProps> = ({
  initializingZone,
  isOnline,
  zone,
  zoneInitialized,
  zoneIsInvalid,
}) => {
  return (
    <>
      {!isOnline && (
        <St.OfflineAlert alertType={'warning'}>
          You are currently offline. Functionality is limited.
        </St.OfflineAlert>
      )}

      <Titlebar initializingZone={initializingZone} selectedZone={zone} />

      {(initializingZone || !zoneInitialized) && <Loader transparent={true} />}

      {zoneInitialized && zone && (
        <Switch>
          <Redirect
            exact={true}
            from="/zone/:zoneId"
            to="/zone/:zoneId/schedule"
          />
          <Route component={ScheduleRouter} path="/zone/:zoneId/schedule" />
          <Route
            component={HousekeepersContainer}
            path="/zone/:zoneId/housekeepers"
          />
          <Route component={ZoneManageContainer} path="/zone/:zoneId/manage" />
          <Route component={NotFoundPage} />
        </Switch>
      )}

      {zoneIsInvalid && <NotFoundPage />}
    </>
  )
}
