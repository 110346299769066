import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePrevious } from 'react-use'

import { useToast } from 'packages/common/src/Toast'
import { AsyncState, useAsyncFnWithReset } from 'packages/utils/hooks'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Clean, CleanPatchData } from 'app/hkhub/store/cleans'
import { updateClean } from 'app/hkhub/store/cleans/actions'
import { setLoadingCleanId } from 'app/hkhub/store/ui/actions'
import { getLoadingCleanId } from 'app/hkhub/store/ui/selectors'

type UseCleanUpdate = {
  resetUpdateCleanState: () => void
  updateClean: (data: Omit<CleanPatchData, 'id'>) => Promise<void>
  updateCleanState: AsyncState<unknown>
}

/**
 * Hook for handling clean updates. Wraps "useAsyncFnWithReset" allowing
 * the loading state to reflect the internally tracked state from that function
 * combined with the redux "loading clean" UI state.
 *
 * Exposes:
 * - the update function
 * - error and loading states
 * - a reset function
 *
 * Optionally calls an "onSuccess" callback on successful clean updates
 */
export const useCleanUpdate = (
  clean: Clean,
  onSuccess?: () => void,
): UseCleanUpdate => {
  const reduxDispatch = useDispatch()
  const loadingCleanId = useSelector(getLoadingCleanId)

  const { showToast } = useToast()

  const { t } = useI18n()

  const [updateState, updateCleanFn, resetUpdateState] = useAsyncFnWithReset(
    async (patchData: CleanPatchData) => {
      reduxDispatch(setLoadingCleanId(clean.id))
      return reduxDispatch(updateClean(patchData))
    },
    [],
  )

  const updateCleanCB: UseCleanUpdate['updateClean'] = React.useCallback(
    async data => {
      await updateCleanFn({ ...data, id: clean.id })
    },
    [clean.id, updateCleanFn],
  )

  const updateCleanStateWithReduxLoading = React.useMemo(() => {
    const loading = !!(updateState.loading || loadingCleanId)
    return {
      ...updateState,
      loading,
    }
  }, [updateState, loadingCleanId])

  // Here we only care if this particular request was previously loading, so we don't look at the loading clean id
  const prevLoading = usePrevious(updateState.loading)

  // Calls an optional "onSuccess" function when the clean is successfully updated
  React.useEffect(() => {
    if (
      !updateCleanStateWithReduxLoading.error &&
      !updateState.loading &&
      prevLoading
    ) {
      if (onSuccess) {
        onSuccess()
      }

      showToast({ message: t(Slugs.cleanUpdatedToast) })
    }
  }, [
    onSuccess,
    prevLoading,
    showToast,
    t,
    updateCleanStateWithReduxLoading.error,
    updateState.loading,
  ])

  return {
    resetUpdateCleanState: resetUpdateState,
    updateClean: updateCleanCB,
    updateCleanState: updateCleanStateWithReduxLoading,
  }
}
