import React from 'react'
import { useDispatch } from 'react-redux'

import { useCurrentCardSize } from 'app/hkhub/components/schedule/helpers/scheduleMeasure'
import { setCleanDrawerState } from 'app/hkhub/store/ui/actions'

import { CleanCard, CleanCardPassedProps } from './CleanCard'

export const CleanCardContainer: React.FC<CleanCardPassedProps> = props => {
  const dispatch = useDispatch()
  const size = useCurrentCardSize()

  const handleOpenCleanDrawer = React.useCallback(
    (cleanId: string) =>
      dispatch(setCleanDrawerState({ cleanId, isOpen: true })),
    [dispatch],
  )

  return <CleanCard {...props} onClick={handleOpenCleanDrawer} size={size} />
}
