import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMountedState } from 'react-use'

import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { DeepCleanSkipPostData } from 'app/hkhub/store/deepCleanSkips'
import { createDeepCleanSkip } from 'app/hkhub/store/deepCleanSkips/actions'
import { getAuthUserId } from 'app/hkhub/store/users/selectors'

import { DeepCleanSkipForm, DeepCleanSkipFormProps } from './DeepCleanSkipForm'
import { validateDeepCleanSkipForm } from './DeepCleanSkipForm.helpers'

type FormValues = DeepCleanSkipFormProps['formState']['values']

type DeepCleanSkipFormContainerProps = {
  onSuccess?: () => void
  unitId: string
}

export const DeepCleanSkipFormContainer: React.FC<DeepCleanSkipFormContainerProps> =
  React.memo(({ onSuccess, unitId }) => {
    const dispatch = useDispatch()
    const userId = useSelector(getAuthUserId)

    const [editing, setEditing] = React.useState(false)
    const [values, setValues] = React.useState<FormValues>({
      reason: '',
    })

    const handleChange = React.useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const reason = event.target.value || ''
        setValues(prev => ({
          ...prev,
          reason,
        }))
      },
      [],
    )

    const isMounted = useMountedState()

    const successHandler = React.useCallback(() => {
      if (isMounted()) setEditing(false)
      if (onSuccess) onSuccess()
    }, [isMounted, onSuccess])

    const [submitState, submitFn, resetSubmitState] = useAsyncFnWithReset(
      async (postData: DeepCleanSkipPostData) =>
        dispatch(createDeepCleanSkip(postData, { onSuccess: successHandler })),
      [dispatch, successHandler],
    )

    const handleSubmit = React.useCallback(
      async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        submitFn({
          reason: values.reason.trim(),
          unitId,
          userId,
        })
      },
      [submitFn, unitId, userId, values.reason],
    )

    // reset state before opening the form
    const handleEdit = React.useCallback(() => {
      resetSubmitState()
      setValues({ reason: '' })
      setEditing(true)
    }, [resetSubmitState])

    const formState: DeepCleanSkipFormProps['formState'] = React.useMemo(() => {
      const canSubmit = validateDeepCleanSkipForm(values)
      return {
        canSubmit,
        editing,
        values,
      }
    }, [editing, values])

    const handlers: DeepCleanSkipFormProps['handlers'] = React.useMemo(() => {
      return {
        cancel: () => setEditing(false),
        change: handleChange,
        edit: handleEdit,
        setEditing,
        submit: handleSubmit,
      }
    }, [handleChange, handleEdit, handleSubmit])

    return (
      <DeepCleanSkipForm
        formState={formState}
        handlers={handlers}
        requestState={submitState}
      />
    )
  })
