import { TranslatorFn } from 'packages/i18n'

import { DateCleanBuckets } from 'app/hkhub/store/cleans/cleans.types'
import { Reservation } from 'app/hkhub/store/reservations/reservations.types'
import { Unit } from 'app/hkhub/store/units/units.types'

export enum UnitFilterType {
  Occupancy = 'Occupancy',
  UnitStatus = 'UnitStatus',
}

export type UnitFilterBuckets = Record<UnitFilterType, UnitViewFilter[]>

export type UnitCleanFilterData = {
  cleans: DateCleanBuckets
  reservations: Reservation[]
  unit: Unit
}

/*
 * Base type for the filtering objects.
 *
 * 'process' is the actual function that determines whether a given set of data should be shown
 * 'toString' should be used for a defining a human-readable version of the current state of the filter
 *      (i.e. for use in showing current filter Pills)
 */
export type UnitViewFilter = {
  process: (data: UnitCleanFilterData) => boolean
  toString: () => string
  toUiLabel: (t: TranslatorFn) => string
}
