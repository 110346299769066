import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useCurrentCardSize } from 'app/hkhub/components/schedule/helpers/scheduleMeasure'
import { ApplicationState } from 'app/hkhub/store/store'
import { setVisitDrawerState } from 'app/hkhub/store/ui/actions'
import { getUnitById } from 'app/hkhub/store/units/selectors'

import { VisitCard, VisitCardPassedProps } from './VisitCard'

export const VisitCardContainer: React.FC<VisitCardPassedProps> = props => {
  const size = useCurrentCardSize()

  const dispatch = useDispatch()

  const unit = useSelector((state: ApplicationState) =>
    getUnitById(state.units, props.visit.unitId),
  )

  const handleCardClick = React.useCallback(() => {
    dispatch(setVisitDrawerState({ isOpen: true, visitId: props.visit.id }))
  }, [dispatch, props.visit.id])

  return unit ? (
    <VisitCard {...props} unit={unit} size={size} onClick={handleCardClick} />
  ) : null
}
