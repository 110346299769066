import styled from '@emotion/styled'

import { Drawer, VersionDisplay } from 'packages/common'
import { colors } from 'packages/styles'

import { ZoneSelector as BaseZoneSelector } from 'app/hkhub/components/core/components/ZoneSelector/ZoneSelector'

const PADDING_X = 24

export const StyledDrawer = styled(Drawer)`
  background: ${colors.midnight};
  border-left: none;
  padding-bottom: 0;
  min-width: 300px;
`

export const DrawerContents = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-bottom: 8px;
  padding-top: 50px;
`

export const ZoneSelectorWrapper = styled.div`
  align-items: center;
  border-bottom: 1px ${colors.midnight60} solid;
  border-top: 1px ${colors.midnight60} solid;
  display: flex;
  height: 56px;
  justify-content: center;
  width: 100%;
`

export const ZoneSelector = styled(BaseZoneSelector)`
  // using margin here instead of padding ensures that the attached menu also gets sized correctly
  margin: 0 16px;
`

export const navLink = `
  color: ${colors.white};
  font-size: 18px;

  &:hover {
    font-weight: bold;
  }
`

export const navLinkActive = `
  font-weight: bold;
`

export const NavLinksWrapper = styled.ul`
  list-style: none;
  padding-left: ${PADDING_X}px;
  padding-top: 16px;

  li + li {
    margin-top: 24px;
  }
`

export const TabWithNotification = styled.span`
  align-items: center;
  display: inline-flex;
  justify-content: space-between;

  // Div here is the notification badge
  & > div {
    background: white;
    color: ${colors.midnight};
    margin-left: 8px;
  }
`

export const DrawerVersionDisplay = styled(VersionDisplay)`
  justify-content: flex-start;
  padding-left: ${PADDING_X}px;
`

export const Buttons = styled.div`
  margin-top: auto;
  padding: 8px 24px;
`
