import styled from '@emotion/styled'
import React from 'react'

import { Breakpoint } from 'packages/styles'
import { DateFormat, format } from 'packages/utils/dateHelpers'

import { useScheduleContext } from 'app/hkhub/components/schedule/components/common/VirtualizedSchedule/VirtualizedSchedule.context'
import { ScheduleRowEventsContainer } from 'app/hkhub/components/schedule/components/schedule.styles'
import { getTasksForDate } from 'app/hkhub/components/schedule/helpers/cleanHelpers'
import {
  cardNotTiny,
  useCurrentCardSize,
} from 'app/hkhub/components/schedule/helpers/scheduleMeasure'
import { DateCleanBuckets } from 'app/hkhub/store/cleans'
import { Reservation } from 'app/hkhub/store/reservations/reservations.types'
import { DateTicketBuckets, Ticket } from 'app/hkhub/store/tickets'
import { Unit } from 'app/hkhub/store/units/units.types'

import { ResShimmer } from '../../unitSchedule.styles'
import { UnitScheduleEventsContainer } from './UnitScheduleEvents.container'
import { UnitScheduleRowHeaderContainer } from './UnitScheduleRowHeader'

const St = {
  EventsContainer: styled(ScheduleRowEventsContainer)`
    position: relative;
  `,
}

function getTicketsForDate(
  dateTicketBuckets: DateTicketBuckets,
  date: Date,
): Ticket[] {
  const dateKey = format(date, 'yyyy-MM-dd')
  const ticketsForDate = dateTicketBuckets[dateKey]
  return ticketsForDate ?? []
}

export enum UnitScheduleRowTestIds {
  resShimmer = 'UnitScheduleRow__resShimmer',
}

export type UnitScheduleRowProps = {
  breakpoint: Breakpoint
  cleans: DateCleanBuckets
  isLoadingReservations: boolean
  reservations: Reservation[]
  // this is the "style" object passed down by react-window
  style: any // eslint-disable-line
  tickets: DateTicketBuckets
  unit: Unit
}

export const UnitScheduleRow: React.FC<UnitScheduleRowProps> = ({
  breakpoint,
  cleans,
  isLoadingReservations,
  reservations,
  tickets,
  style,
  unit,
}) => {
  const { dateRange } = useScheduleContext()
  const cardSize = useCurrentCardSize()

  return (
    <div style={style}>
      <UnitScheduleRowHeaderContainer breakpoint={breakpoint} unit={unit} />

      <St.EventsContainer>
        {isLoadingReservations && cardNotTiny(cardSize) && (
          <ResShimmer data-testid={UnitScheduleRowTestIds.resShimmer} />
        )}

        {dateRange.map(date => {
          return (
            <UnitScheduleEventsContainer
              cleans={getTasksForDate(cleans, date)}
              date={date}
              key={format(date, DateFormat.ApiUtcShort)}
              reservations={reservations}
              tickets={getTicketsForDate(tickets, date)}
            />
          )
        })}
      </St.EventsContainer>
    </div>
  )
}
