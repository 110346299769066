import { createSelector } from 'reselect'

import { ApplicationState } from 'app/hkhub/store/store'

import { VisitDrawerState } from '../ui.types'

export const getVisitDrawerState = createSelector(
  (state: ApplicationState) => state.ui.drawers.visitDrawer,
  (visitDrawerState: VisitDrawerState) => visitDrawerState,
)
