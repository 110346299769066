import classNames from 'classnames'
import capitalize from 'lodash/capitalize'
import every from 'lodash/every'
import noop from 'lodash/fp/noop'
import startCase from 'lodash/startCase'
import * as React from 'react'
import { ValueType } from 'react-select/src/types'

import { Button, ContentModal, Alert, InputField } from 'packages/common'
import { SvgIcon, IconName } from 'packages/iconic'

import AsyncUserSelector from 'app/hkhub/components/core/components/AsyncUserSelector/AsyncUserSelector'
import selectorStyles from 'app/hkhub/components/core/components/AsyncUserSelector/AsyncUserSelectorOverride.module.scss'
import { Slugs, useI18n } from 'app/hkhub/i18n'
import { User } from 'app/hkhub/store/users/users.types'
import { Zone } from 'app/hkhub/store/zones/zones.types'

import styles from './EditZoneModal.module.scss'

export type EditZoneModalProps = Omit<
  EditZoneModalContentProps,
  'beginTransitionOut'
>

export type EditZoneModalContentProps = {
  maxWidth: number
  onModalClose: () => void
  showing: boolean
  zone?: Zone
} & EditZoneNameSectionProps &
  EditZoneMangerSectionProps &
  ButtonSectionProps

/** Value the API will return if an error is a zone duplication error */
const API_DUPLICATE_ZONE_ERROR = 'zone with this name already exists.'

type EditZoneNameSectionProps = {
  apiError: string
  loading: boolean
  onZoneNameChange: (value: string) => void
  zoneName: string
}

export const EditZoneNameSection: React.FC<EditZoneNameSectionProps> = ({
  apiError,
  loading,
  onZoneNameChange,
  zoneName,
}) => {
  const { t } = useI18n()

  /** Here we need to determine if the error is due to an attempt to save duplicate zone name */
  const isZoneNameError = apiError === API_DUPLICATE_ZONE_ERROR
  /** If the error is due to duplication, we pass it directly to the input field */
  const zoneNameInputErrorMessage = isZoneNameError
    ? t(Slugs.zoneNameAlreadyExists)
    : ''
  /** If there is an error for any other reason, we use this to show a Danger `Alert` */
  const unknownError = !isZoneNameError && !!apiError

  return (
    <>
      {unknownError && (
        <Alert alertType={'danger'}>
          {startCase(t(Slugs.anErrorOccurred))}
        </Alert>
      )}
      <div className={styles.nameSection}>
        <span className={styles.zoneNameHeader}>{t(Slugs.zoneName)}</span>
        <InputField
          className={styles.zoneNameInput}
          disabled={loading}
          errorMessage={zoneNameInputErrorMessage}
          initialValue={zoneName}
          maxLength={22}
          onInputChange={onZoneNameChange}
          showCount={true}
        />
      </div>
    </>
  )
}

type EditZoneMangerSectionProps = {
  currentManagers: (User | null)[]
  getUsersSearchResults: () => User[]
  isSearchableLength: boolean
  loading: boolean
  onAddNewManager: () => void
  onRemoveManager: (idx: number) => void
  onUserSelectionChange: (value: ValueType<User>, idx: number) => void
  searchUsers: (input: string) => Promise<void>
}

export const EditZoneManagerSection: React.FC<EditZoneMangerSectionProps> =
  React.memo(
    ({
      currentManagers,
      getUsersSearchResults,
      isSearchableLength,
      loading,
      onAddNewManager,
      onRemoveManager,
      onUserSelectionChange,
      searchUsers,
    }) => {
      const { t, tp } = useI18n()
      return (
        <div className={styles.managerSection}>
          <span className={styles.sectionHeader}>
            {capitalize(tp(Slugs.manager, currentManagers.length, false))}
          </span>
          {currentManagers.map((manager, idx) => (
            <span key={idx} className={styles.userSelectorWrapper}>
              <AsyncUserSelector
                className={classNames(selectorStyles.selector, {
                  [selectorStyles.noSearch]: !isSearchableLength,
                })}
                getUsersResults={getUsersSearchResults}
                isDisabled={loading}
                onUserSelectionChange={value =>
                  onUserSelectionChange(value, idx)
                }
                searchUsers={searchUsers}
                selectedUser={manager}
              />
              <SvgIcon
                className={styles.closeIcon}
                dataTestId={'removeButton'}
                icon={IconName.timesCircle}
                onClick={loading ? noop : () => onRemoveManager(idx)}
                size={24}
              />
            </span>
          ))}
          <div className={styles.addManagerButtonWrapper}>
            {every(currentManagers) && currentManagers.length < 5 && (
              <Button
                className={styles.addManagerButton}
                onClick={onAddNewManager}
                buttonType={'text'}
              >
                {`+ ${startCase(t(Slugs.addManager))}`}
              </Button>
            )}
          </div>
        </div>
      )
    },
  )

type ButtonSectionProps = {
  allowSave: boolean
  apiError: string
  beginTransitionOut: () => void
  loading: boolean
  onSaveZone: () => Promise<Record<string, unknown>>
}

const ButtonSection: React.FC<ButtonSectionProps> = React.memo(
  ({ allowSave, apiError, beginTransitionOut, loading, onSaveZone }) => {
    const { t } = useI18n()

    const handleSaveButtonClick = React.useCallback(
      async e => {
        e.preventDefault()
        try {
          await onSaveZone()
          beginTransitionOut()
        } catch (e) {
          // errors are handled in parent; nothing specific to do here
        }
      },
      [beginTransitionOut, onSaveZone],
    )

    return (
      <div className={styles.buttonSection}>
        <Button
          disabled={loading}
          onClick={beginTransitionOut}
          buttonType={'secondary'}
        >
          {startCase(t(Slugs.cancel))}
        </Button>

        <Button
          disabled={!allowSave || !!apiError}
          isFormSubmit={true}
          isLoading={loading}
          onClick={handleSaveButtonClick}
          buttonType={'primary'}
        >
          {startCase(t(Slugs.save))}
        </Button>
      </div>
    )
  },
)

export const EditZoneModalContent: React.FC<EditZoneModalContentProps> = ({
  allowSave,
  apiError,
  beginTransitionOut,
  currentManagers,
  getUsersSearchResults,
  isSearchableLength,
  loading,
  onAddNewManager,
  onRemoveManager,
  onSaveZone,
  onUserSelectionChange,
  onZoneNameChange,
  searchUsers,
  zoneName,
}) => {
  return (
    <form>
      <EditZoneNameSection
        apiError={apiError}
        loading={loading}
        onZoneNameChange={onZoneNameChange}
        zoneName={zoneName}
      />
      <EditZoneManagerSection
        currentManagers={currentManagers}
        getUsersSearchResults={getUsersSearchResults}
        isSearchableLength={isSearchableLength}
        loading={loading}
        onAddNewManager={onAddNewManager}
        onRemoveManager={onRemoveManager}
        onUserSelectionChange={onUserSelectionChange}
        searchUsers={searchUsers}
      />
      <ButtonSection
        allowSave={allowSave}
        apiError={apiError}
        beginTransitionOut={beginTransitionOut}
        loading={loading}
        onSaveZone={onSaveZone}
      />
    </form>
  )
}

const EditZoneModal: React.FC<EditZoneModalProps> = props => {
  const { t } = useI18n()
  return (
    <ContentModal
      afterClose={props.onModalClose}
      canModalClose={!props.loading}
      maxWidth={props.maxWidth}
      overflow={'visible'}
      showing={props.showing}
      renderChildren={({ beginTransitionOut }) => (
        <EditZoneModalContent
          {...props}
          beginTransitionOut={beginTransitionOut}
        />
      )}
      title={startCase(t(Slugs.editZone))}
    />
  )
}

export default React.memo(EditZoneModal)
