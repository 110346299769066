import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ApplicationState } from 'app/hkhub/store/store'
import { getIncompleteTicketCountByUnitId } from 'app/hkhub/store/tickets/selectors'
import { setUnitDrawerState } from 'app/hkhub/store/ui/actions'

import {
  UnitScheduleRowHeader,
  UnitScheduleRowHeaderProps,
} from './UnitScheduleRowHeader'

export type UnitScheduleRowHeaderContainerProps = Pick<
  UnitScheduleRowHeaderProps,
  'breakpoint' | 'unit'
>

export const UnitScheduleRowHeaderContainer: React.FC<
  UnitScheduleRowHeaderContainerProps
> = ({ breakpoint, unit }) => {
  const dispatch = useDispatch()

  const openUnitDrawer = React.useCallback(() => {
    dispatch(
      setUnitDrawerState({
        isOpen: true,
        unitId: unit.id,
      }),
    )
  }, [dispatch, unit])

  const overdueTicketCount = useSelector((state: ApplicationState) =>
    getIncompleteTicketCountByUnitId(state, unit.id),
  )

  return (
    <UnitScheduleRowHeader
      breakpoint={breakpoint}
      onOpenUnitDrawer={openUnitDrawer}
      overdueTicketCount={overdueTicketCount}
      unit={unit}
    />
  )
}
